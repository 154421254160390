import React from "react";
import Card from "react-bootstrap/Card";
import { FaCode } from "react-icons/fa";
import Config from "../../Config/Config";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">{Config.DeveloperName} </span>
            from <span className="purple"> {Config.DeveloperFrom}.</span>
            <br />{Config.DeveloperSummary}
            <br />
            <br />
            Apart from work, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <FaCode /> Learning new technology's
            </li>
            <li className="about-activity">
              <FaCode /> Chatting with AI Tools like Chat-Gpt
            </li>
            <li className="about-activity">
              <FaCode /> Do some crazy useful projects like this portfolio
            </li>
            <li className="about-activity">
              <FaCode /> Spend With Friends With Family
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Any fool can write code that a computer can understand. Good programmers write code that humans can understand.”{" "}
          </p>
          {/* <footer className="blockquote-footer">Martin Fowler</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
