const Config = {
    ResumeLink:  "https://drive.google.com/file/d/1zfgz-PMqfBEj-ts_a7lMSKJJfvbByMPa/view?usp=sharing",
    DeveloperName: `Ashif Sadiq`,
    DeveloperFrom: `Tamil Nadu, India`,
    DeveloperSummary: `I'm Ashif, a seasoned full-stack mobile app developer with nearly 2 years of experience. I've been thriving in remote work at AI Bots, where I've contributed to diverse projects. My expertise spans both front-end and back-end, allowing me to create captivating interfaces backed by robust functionality. I'm dedicated to staying updated with the latest trends, ensuring my work is innovative and forward-looking. Let's connect and create impactful mobile experiences.`,
    // DeveloperSummary: `I am a full-stack developer currently working as a mobile application developer at Falcon Square Pvt. Ltd. in Coimbatore, Tamil Nadu, India.`,
    SocialLinks: {
        LinkedIn:"https://www.linkedin.com/in/ashifsadiq",
        GitHub:"https://github.com/ashifsadiq",
        Twitter:"https://twitter.com/ashifsadiq",
        Instagram:"https://www.instagram.com/ashifsadiq"
    }
}
export default Config;